@font-face {
  font-family: Prompt-Reg;
  src: url("Prompt-Regular_0.669ad166.ttf");
}

@font-face {
  font-family: Prompt-Bold;
  src: url("Prompt-Bold_0.7d1f21aa.ttf");
}

body {
  max-width: 500px;
  max-height: 1000px;
  margin: 0 auto;
}

body #gameCanvas {
  background-image: url("Main-Menu.6cd30a01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

body #gameCanvas .start-menu {
  height: 100vh;
  width: 100vw;
  background-image: url("Main-Menu.6cd30a01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
}

body #gameCanvas .start-menu .token-left {
  margin: 2vh 0 0 5vw;
  display: flex;
}

body #gameCanvas .start-menu .token-left img {
  width: 10%;
  height: 10%;
}

body #gameCanvas .start-menu .token-left p {
  text-shadow: 2px 4px #5a0713;
  color: #fff;
  margin: .3vh 0 0 2.5vw;
  font-family: Prompt-Bold;
  font-size: 6vw;
}

body #gameCanvas .start-menu .visa-logo {
  max-width: 65vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4.5vh;
}

body #gameCanvas .start-menu .visa-logo img {
  width: 100%;
}

body #gameCanvas .start-menu .game-logo {
  max-width: 90%;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
}

body #gameCanvas .start-menu .game-logo img {
  width: 100%;
}

body #gameCanvas .start-menu .play-btn {
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5vh;
  display: flex;
}

body #gameCanvas .start-menu .play-btn button {
  color: #fff;
  background-color: #d41c24;
  border: none;
  border-radius: 7vw;
  padding: 1vh 16vw;
  font-family: Prompt-Bold;
  font-size: 6vw;
  box-shadow: 1vw .6vh #a5131e;
}

body #gameCanvas .start-menu .play-btn button:active {
  transform: translateY(.5vh);
  box-shadow: .5vw 0 #a5131e;
}

body #gameCanvas .start-menu .reward-btn {
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2vh;
  display: flex;
}

body #gameCanvas .start-menu .reward-btn button {
  color: #fff;
  background-color: #d12020;
  border: none;
  border-radius: 7vw;
  padding: 1.2vh 5vw;
  font-family: Prompt-Bold;
  font-size: 6vw;
  box-shadow: 1vw .6vh #a5131e;
}

body #gameCanvas .start-menu .reward-btn button:active {
  transform: translateY(.5vh);
  box-shadow: .5vw 0 #a5131e;
}

body #gameCanvas .start-menu .banner-lower {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3.5vh;
}

body #gameCanvas .start-menu .banner-lower img {
  width: 85vw;
}

body #gameCanvas .start-menu .football {
  max-width: 60%;
  margin-top: 3.5vh;
  margin-left: auto;
  margin-right: auto;
}

body #gameCanvas .start-menu .football img {
  width: 100%;
}

body #gameCanvas .animate-fadein {
  position: flex;
  animation-name: animatefadein;
  animation-duration: 1.5s;
}

@keyframes animatefadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body #gameCanvas .animate-slidein {
  position: flex;
  animation-name: animateslidein;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-direction: alternate;
}

@keyframes animateslidein {
  from {
    transform: translateX(550px);
  }

  to {
    transform: translateX(0);
  }
}

body #gameCanvas .loading-menu {
  width: 100%;
  height: 100vh;
  background-image: url("Main-Menu.6cd30a01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: absolute;
}

body #gameCanvas .loading-menu .fifa-logo {
  max-width: 90%;
  position: relative;
}

body #gameCanvas .loading-menu .fifa-logo img {
  width: 100%;
}

body #gameCanvas .loading-game {
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: #252525b3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  display: flex;
  position: absolute;
}

body #gameCanvas .loading-game #loader {
  z-index: 1;
  width: 65px;
  height: 65px;
  border: 8px solid #f3f3f3;
  border-top-color: #8b1738;
  border-radius: 50%;
  animation: 2s linear infinite spin;
}

body #gameCanvas .loading-game p {
  color: #fff;
  text-shadow: 2px 6px #5a0713;
  margin-top: 2vh;
  font-family: Prompt-Bold;
  font-size: 8vw;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

body #gameCanvas .tutorial-page {
  width: 100vw;
  height: 100vh;
  background-image: url("Main-Menu.6cd30a01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5vh;
  margin: 0 auto;
  display: flex;
  position: absolute;
}

body #gameCanvas .tutorial-page .header-img {
  width: 100vw;
  justify-content: center;
  display: flex;
}

body #gameCanvas .tutorial-page .header-img img {
  width: 85vw;
}

body #gameCanvas .tutorial-page .skip-tutorial {
  z-index: 1;
  width: 10vw;
  height: 5vh;
  position: absolute;
  top: 11vh;
  right: 3vw;
}

body #gameCanvas .tutorial-page .skip-tutorial img {
  width: 100%;
  height: 100%;
}

body #gameCanvas .tutorial-page .splide {
  width: 100vw;
  height: 80vh;
  margin: 0 auto;
}

body #gameCanvas .tutorial-page .splide img {
  width: 100%;
  height: 100%;
}

body #gameCanvas .select-char-menu {
  width: 100%;
  object-fit: cover;
  background-image: url("Select-Char.1c86902f.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

body #gameCanvas .select-char-menu .i-icon {
  width: 96vw;
  justify-content: end;
  margin-top: 1.5vh;
  display: flex;
  position: absolute;
  top: 0;
}

body #gameCanvas .select-char-menu .i-icon img {
  width: 8.5%;
  padding: 1vh 2vw;
}

body #gameCanvas .select-char-menu .player-head {
  text-align: center;
  width: 100vw;
  height: 0;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: Prompt-Bold;
  position: absolute;
  top: 7vh;
}

body #gameCanvas .select-char-menu .player-head h1 {
  height: 0;
  margin: 0;
}

body #gameCanvas .select-char-menu .btn-row-txt {
  text-align: center;
  width: 100vw;
  color: #fff;
  text-shadow: 2px 4px #5a0713;
  font-family: Prompt-Bold;
  font-size: 6vw;
  position: absolute;
  top: 57vh;
}

body #gameCanvas .select-char-menu .button-char {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 1vh;
  display: flex;
  position: absolute;
  top: 66.5vh;
}

body #gameCanvas .select-char-menu .button-char .btn-row {
  filter: drop-shadow(0 .5vh #000);
  justify-content: center;
  align-items: center;
  column-gap: 2vh;
  display: flex;
}

body #gameCanvas .select-char-menu .button-char .btn-row button {
  clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
  border: none;
  border-radius: 0 .8rem;
  padding: 1vh 5vw;
  font-family: Prompt-Bold;
  box-shadow: 1vw .6vh #a5131e;
}

body #gameCanvas .select-char-menu .button-char .btn-row button:active {
  transform: translateY(.5vh);
  box-shadow: .5vw 0 #a5131e;
}

body #gameCanvas .select-char-menu .button-char .btn-row button#load_argen {
  color: #fff;
  background-color: #6d1f49;
}

body #gameCanvas .select-char-menu .button-char .btn-row button#load_belgium {
  color: #fff;
  background-color: #c01633;
}

body #gameCanvas .select-char-menu .button-char .btn-row button#load_brazil {
  color: #fff;
  background-color: #7aa9d7;
}

body #gameCanvas .select-char-menu .button-char .btn-row button#load_england {
  color: #fff;
  background-color: #001789;
}

body #gameCanvas .select-char-menu .button-char .btn-row button#load_france {
  color: #fff;
  background-color: #f48f1c;
}

body #gameCanvas .select-char-menu .button-char .btn-row button#load_portugal {
  color: #fff;
  background-color: #991a25;
}

body #gameCanvas .select-char-menu .button-char .btn-row button#load_qatar {
  color: #fff;
  background-color: #2f9847;
}

body #gameCanvas .select-char-menu .button-char .btn-row button#load_spain {
  color: #fff;
  background-color: #016933;
}

body #gameCanvas .select-char-menu .main-game-btn {
  width: 100%;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 87vh;
}

body #gameCanvas .select-char-menu .main-game-btn button {
  color: #fff;
  background-color: red;
  border: none;
  border-radius: 7vw;
  padding: 1vh 8vw;
  font-family: Prompt-Bold;
  font-size: 5.5vw;
  box-shadow: 1vw .6vh #a5131e;
}

body #gameCanvas .select-char-menu .main-game-btn button:active {
  transform: translateY(.5vh);
  box-shadow: .5vw 0 #a5131e;
}

body #gameCanvas .game-interface {
  position: absolute;
}

body #gameCanvas .game-interface .goal-show {
  flex-direction: column;
  place-items: center;
  margin-top: 55vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: fixed;
}

body #gameCanvas .game-interface .goal-show .goalWord {
  width: 90%;
}

body #gameCanvas .game-interface .goal-show .goalBg {
  width: 40%;
  margin-top: 2vh;
}

body #gameCanvas .game-interface .goal-show p {
  color: #fff;
  margin: 8vh 0 0;
  font-family: Prompt-Bold;
  font-size: 12vw;
  font-style: italic;
  position: absolute;
}

body #gameCanvas .game-interface .swipe-top-play {
  height: 30vh;
  width: 100%;
  justify-content: center;
  animation: 1.1s linear infinite swipeBlink;
  display: flex;
  position: fixed;
  top: 64vh;
}

body #gameCanvas .game-interface .swipe-top-play img {
  height: 60%;
  width: 60%;
}

@keyframes swipeBlink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 5;
  }

  100% {
    opacity: 1;
  }
}

body #gameCanvas .game-interface .ui-container {
  width: 50%;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  position: fixed;
  top: 93vh;
  left: 1vw;
}

body #gameCanvas .game-interface .ui-container .football-left {
  width: 90%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  display: grid;
}

body #gameCanvas .game-interface .ui-container .football-left .football-display {
  margin-left: 1vw;
}

body #gameCanvas .game-interface .ui-container .football-left .football-display img {
  width: 100%;
  margin: 0 auto;
}

body #gameCanvas .game-interface .ui-empty {
  width: 50%;
  z-index: 1;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  position: fixed;
  top: 93vh;
  left: 1vw;
}

body #gameCanvas .game-interface .ui-empty .football-empty {
  width: 90%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  display: grid;
}

body #gameCanvas .game-interface .ui-empty .football-empty .circle-display {
  margin-left: 1vw;
}

body #gameCanvas .game-interface .ui-empty .football-empty .circle-display img {
  width: 98%;
  margin: 0 auto;
}

body #gameCanvas .game-interface .score-display-lower {
  text-shadow: 2px 4px #5a0713;
  color: #fff;
  font-family: Prompt-Bold;
  font-size: 8vw;
  position: fixed;
  top: 92vh;
  left: 65vw;
}

body #gameCanvas .final-score-ui {
  width: 100%;
  flex-direction: column;
  position: absolute;
}

body #gameCanvas .final-score-ui .token-left {
  width: 8%;
  margin: 0 0 0 5vw;
  padding-top: 3vh;
  display: flex;
}

body #gameCanvas .final-score-ui .token-left img {
  width: 100%;
}

body #gameCanvas .final-score-ui .token-left p {
  text-shadow: 2px 4px #5a0713;
  color: #fff;
  margin: 0;
  font-family: Prompt-Bold;
  font-size: 7vw;
  position: absolute;
  top: 2.8vh;
  left: 15vw;
}

body #gameCanvas .final-score-ui .score-word {
  height: 10vh;
  width: 70%;
  justify-content: center;
  margin: 10vh auto 0;
  display: flex;
}

body #gameCanvas .final-score-ui .score-word img {
  width: 100%;
}

body #gameCanvas .final-score-ui .final-score {
  width: 80%;
  justify-content: center;
  margin: 0 auto;
  padding-top: 3vh;
  display: flex;
}

body #gameCanvas .final-score-ui .final-score img {
  width: 100%;
}

body #gameCanvas .final-score-ui .final-score p {
  color: #fff;
  margin: 0;
  font-family: Prompt-Bold;
  font-size: 20vw;
  font-style: italic;
  position: absolute;
  top: 28vh;
}

body #gameCanvas .final-score-ui .play-again-btn {
  justify-content: center;
  padding-top: 8vh;
  display: flex;
}

body #gameCanvas .final-score-ui .play-again-btn button {
  color: #fff;
  background: #d12020 url("playAgain.71ea87d5.png") 5vw 1.6vh / 8vw 3.5vh no-repeat;
  border: none;
  border-radius: 7vw;
  padding: 1.3vh 4vw 1.3vh 16vw;
  font-family: Prompt-Bold;
  font-size: 6vw;
  box-shadow: 1vw .6vh #a5131e;
}

body #gameCanvas .final-score-ui .play-again-btn button:active {
  transform: translateY(.5vh);
  box-shadow: .5vw 0 #a5131e;
}

body #gameCanvas .final-score-ui .check-reward {
  justify-content: center;
  padding-top: 3vh;
  display: flex;
}

body #gameCanvas .final-score-ui .check-reward button {
  color: #fff;
  background: #d12020 url("mapp.a8cd93d5.png") 4vw 1.2vh / 9.5vw 4.5vh no-repeat;
  border: none;
  border-radius: 7vw;
  padding: 1.3vh 10vw 1.3vh 19vw;
  font-family: Prompt-Bold;
  font-size: 6vw;
  box-shadow: 1vw .6vh #a5131e;
}

body #gameCanvas .final-score-ui .check-reward button:active {
  transform: translateY(.5vh);
  box-shadow: .5vw 0 #a5131e;
}

/*# sourceMappingURL=index.d5247da4.css.map */
