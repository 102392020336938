$main-menu-bg: url("./img/bg/Main-Menu.jpg");
$select-char-bg: url("./img/bg/Select-Char.png");
$game-bg: url("./img/bg/BG-Gaol.jpg");
@font-face {
  font-family: "Prompt-Reg";
  src: url("./Fonts/Prompt-Regular_0.ttf");
}
@font-face {
  font-family: "Prompt-Bold";
  src: url("./Fonts/Prompt-Bold_0.ttf");
}

body {
  margin: 0 auto;
  max-width: 500px;
  max-height: 1000px;
  #gameCanvas {
    background-image: $main-menu-bg;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: relative;

    .start-menu {
      height: 100vh;
      width: 100vw;
      position: absolute;
      background-image: $main-menu-bg;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;

      .token-left {
        display: flex;
        margin: 2vh 0 0 5vw;
        img {
          width: 10%;
          height: 10%;
        }
        p {
          font-family: "Prompt-Bold";
          font-size: 6vw;
          margin: 0.3vh 0 0 2.5vw;
          text-shadow: 2px 4px #5a0713;
          color: #ffffff;
        }
      }

      .visa-logo {
        padding-top: 4.5vh;
        margin-left: auto;
        margin-right: auto;
        max-width: 65vw;
        img {
          width: 100%;
        }
      }

      .game-logo {
        margin-top: 2vh;
        margin-left: auto;
        margin-right: auto;
        max-width: 90%;
        img {
          width: 100%;
        }
      }

      .play-btn {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 5vh;
        margin-left: auto;
        margin-right: auto;

        button {
          font-family: "Prompt-Bold";
          background-color: #d41c24;
          color: #ffffff;
          border: none;
          border-radius: 7vw;
          font-size: 6vw;
          padding: 1vh 16vw 1vh 16vw;
          box-shadow: 1vw 0.6vh #a5131e;
        }

        button:active {
          transform: translateY(0.5vh);
          box-shadow: 0.5vw 0vh #a5131e;
        }
      }

      .reward-btn {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 2vh;
        margin-left: auto;
        margin-right: auto;

        button {
          font-family: "Prompt-Bold";
          background-color: rgb(209, 32, 32);
          color: #ffffff;
          border: none;
          border-radius: 7vw;
          font-size: 6vw;
          padding: 1.2vh 5vw 1.2vh 5vw;
          box-shadow: 1vw 0.6vh #a5131e;
        }
        button:active {
          transform: translateY(0.5vh);
          box-shadow: 0.5vw 0vh #a5131e;
        }
      }

      .banner-lower {
        padding-top: 3.5vh;
        margin-left: auto;
        margin-right: auto;
        max-width: 85%;
        img {
          width: 85vw;
        }
      }

      .football {
        margin-left: auto;
        margin-right: auto;
        margin-top: 3.5vh;
        max-width: 60%;
        img {
          width: 100%;
        }
      }
    }

    .animate-fadein {
      position: flex;
      animation-name: animatefadein;
      animation-duration: 1.5s;
    }

    @keyframes animatefadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .animate-slidein {
      position: flex;
      animation-name: animateslidein;
      animation-duration: 1s;
      animation-iteration-count: 2;
      animation-direction: alternate;
    }

    @keyframes animateslidein {
      from {
        transform: translateX(550);
      }
      to {
        transform: translateX(0);
      }
    }

    .loading-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-image: $main-menu-bg;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      .fifa-logo {
        position: relative;
        max-width: 90%;
        img {
          width: 100%;
        }
      }
    }

    .loading-game {
      position: absolute;
      display: flex;
      width: 100vw;
      height: 100vh;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0vh;
      z-index: 2;
      background-color: rgba($color: #252525fb, $alpha: 0.7);
      #loader {
        z-index: 1;
        width: 65px;
        height: 65px;
        border: 8px solid #f3f3f3;
        border-radius: 50%;
        border-top: 8px solid #8b1738;
        animation: spin 2s linear infinite;
      }

      p {
        font-family: "Prompt-Bold";
        font-size: 8vw;
        color: #ffffff;
        text-shadow: 2px 6px #5a0713;
        margin-top: 2vh;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .tutorial-page {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: absolute;
      background-image: $main-menu-bg;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 100vw;
      height: 100vh;
      margin: 0 auto;
      gap: 2.5vh;

      .header-img {
        display: flex;
        justify-content: center;
        width: 100vw;
        img {
          width: 85vw;
        }
      }

      .skip-tutorial {
        position: absolute;
        z-index: 1;
        top: 11vh;
        right: 3vw;
        width: 10vw;
        height: 5vh;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .splide {
        width: 100vw;
        margin: 0 auto;
        height: 80vh;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .select-char-menu {
      width: 100%;
      background-image: $select-char-bg;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      object-fit: cover;

      .i-icon {
        position: absolute;
        top: 0;
        display: flex;
        width: 96vw;
        justify-content: end;
        margin-top: 1.5vh;
        img {
          padding: 1vh 2vw 1vh 2vw;
          width: 8.5%;
        }
      }

      .player-head {
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100vw;
        height: 0vh;
        position: absolute;
        top: 7vh;
        font-family: "Prompt-Bold";
        color: white;
        h1 {
          height: 0vh;
          margin: 0;
        }
      }

      .btn-row-txt {
        position: absolute;
        text-align: center;
        width: 100vw;
        top: 57vh;
        font-family: "Prompt-Bold";
        font-size: 6vw;
        color: #ffffff;
        text-shadow: 2px 4px #5a0713;
      }

      .button-char {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        top: 66.5vh;
        width: 100%;
        gap: 1vh;

        .btn-row {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 2vh;
          filter: drop-shadow(0px 0.5vh 0px rgba(0, 0, 0, 1));

          button {
            font-family: "Prompt-Bold";
            clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
            border-radius: 0 0.8rem 0 0.8rem;
            padding: 1vh 5vw 1vh 5vw;
            border: none;
            box-shadow: 1vw 0.6vh #a5131e;
          }
          button:active {
            transform: translateY(0.5vh);
            box-shadow: 0.5vw 0vh #a5131e;
          }

          button#load_argen {
            background-color: #6d1f49;
            color: #ffffff;
          }

          button#load_belgium {
            background-color: #c01633;
            color: #ffffff;
          }

          button#load_brazil {
            background-color: #7aa9d7;
            color: #ffffff;
          }

          button#load_england {
            background-color: #001789;
            color: #ffffff;
          }

          button#load_france {
            background-color: #f48f1c;
            color: #ffffff;
          }

          button#load_portugal {
            background-color: #991a25;
            color: #ffffff;
          }

          button#load_qatar {
            background-color: #2f9847;
            color: #ffffff;
          }

          button#load_spain {
            background-color: #016933;
            color: #ffffff;
          }
        }
      }

      .main-game-btn {
        display: flex;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 87vh;

        button {
          font-family: "Prompt-Bold";
          font-size: 5.5vw;
          padding: 1vh 8vw 1vh 8vw;
          background-color: red;
          color: white;
          border-radius: 7vw;
          border: none;
          box-shadow: 1vw 0.6vh #a5131e;
        }

        button:active {
          transform: translateY(0.5vh);
          box-shadow: 0.5vw 0vh #a5131e;
        }
      }
    }

    .game-interface {
      position: absolute;

      .goal-show {
        position: fixed;
        margin-top: 55vh;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        .goalWord {
          width: 90%;
        }
        .goalBg {
          margin-top: 2vh;
          width: 40%;
        }
        p {
          position: absolute;
          margin: 8vh 0 0 0;
          font-family: "Prompt-Bold";
          font-size: 12vw;
          color: #ffffff;
          font-style: italic;
        }
      }

      .swipe-top-play {
        height: 30vh;
        width: 100%;
        position: fixed;
        display: flex;
        justify-content: center;
        top: 64vh;
        animation: swipeBlink 1.1s linear infinite;
        img {
          height: 60%;
          width: 60%;
        }
      }
      @keyframes swipeBlink {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 5;
        }
        100% {
          opacity: 1;
        }
      }

      .ui-container {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: fixed;
        top: 93vh;
        left: 1vw;
        z-index: 2;

        .football-left {
          width: 90%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          .football-display {
            margin-left: 1vw;
            img {
              margin: 0 auto;
              width: 100%;
            }
          }
        }
      }

      .ui-empty {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: fixed;
        top: 93vh;
        left: 1vw;
        z-index: 1;

        .football-empty {
          width: 90%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          .circle-display {
            margin-left: 1vw;
            img {
              margin: 0 auto;
              width: 98%;
            }
          }
        }
      }

      .score-display-lower {
        position: fixed;
        top: 92vh;
        left: 65vw;
        font-family: "Prompt-Bold";
        font-size: 8vw;
        text-shadow: 2px 4px #5a0713;
        color: #ffffff;
      }
    }

    .final-score-ui {
      // background-image: $game-bg;
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      // background-position: center;

      width: 100%;
      position: absolute;
      flex-direction: column;

      .token-left {
        padding-top: 3vh;
        display: flex;
        margin: 0 0 0 5vw;
        width: 8%;
        img {
          width: 100%;
        }

        p {
          font-family: "Prompt-Bold";
          margin: 0 0 0;
          position: absolute;
          top: 2.8vh;
          left: 15vw;
          font-size: 7vw;
          text-shadow: 2px 4px #5a0713;
          color: #ffffff;
        }
      }

      .score-word {
        height: 10vh;
        margin: 10vh auto 0 auto;
        display: flex;
        justify-content: center;
        width: 70%;
        img {
          width: 100%;
        }
      }

      .final-score {
        padding-top: 3vh;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width: 80%;
        img {
          width: 100%;
        }

        p {
          font-family: "Prompt-Bold";
          font-style: italic;
          margin: 0;
          top: 28vh;
          position: absolute;
          font-size: 20vw;
          color: #ffffff;
        }
      }

      .play-again-btn {
        display: flex;
        justify-content: center;
        padding-top: 8vh;
        button {
          background: url('./img/rewardMenu/playAgain.png');
          background-position: 5vw 1.6vh;
          background-repeat: no-repeat;
          background-size: 8vw 3.5vh;
          font-family: "Prompt-Bold";
          background-color: rgb(209, 32, 32);
          color: #ffffff;
          border: none;
          border-radius: 7vw;
          font-size: 6vw;
          padding: 1.3vh 4vw 1.3vh 16vw;
          box-shadow: 1vw 0.6vh #a5131e;
        }
        button:active {
          transform: translateY(0.5vh);
          box-shadow: 0.5vw 0vh #a5131e;
        }
      }

      .check-reward {
        display: flex;
        justify-content: center;
        padding-top: 3vh;
        button {
          background: url('./img/rewardMenu/mapp.png');
          background-position: 4vw 1.2vh;
          background-repeat: no-repeat;
          background-size: 9.5vw 4.5vh;
          font-family: "Prompt-Bold";
          background-color: rgb(209, 32, 32);
          color: #ffffff;
          border: none;
          border-radius: 7vw;
          font-size: 6vw;
          padding: 1.3vh 10vw 1.3vh 19vw;
          box-shadow: 1vw 0.6vh #a5131e;
        }
        button:active {
          transform: translateY(0.5vh);
          box-shadow: 0.5vw 0vh #a5131e;
        }
      }
    }
  }
}
